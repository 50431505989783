import { useCallback } from 'react'
import { isValidNextPosterInfoState } from '../../../redux/dashboardInputs/actions/nextPosterInfoState'
import type { PosterInfoStateValidProps } from '../../../redux/dashboardInputs/interfaces/PosterInfoStateValidProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'
import { validateFields } from './usePosterInfoValidation'

export const useNextPosterInfoValidation = (): (() => Promise<boolean>) => {
	const {
		guestName,
		guestBio,
		guestSocialHandle,
		guestSocialHyperlink,
		guestImageWithName,
		guestImageWithoutName,
		hostName,
		hostSocialHandle,
		hostSocialHyperlink,
		hostImageWithoutName,
		game,
	} = useAppSelector(
		({
			dashboardInputs: {
				nextPosterInfo: {
					guestName,
					guestBio,
					guestSocialHandle,
					guestSocialHyperlink,
					guestImageWithName,
					guestImageWithoutName,
					hostName,
					hostSocialHandle,
					hostSocialHyperlink,
					hostImageWithoutName,
					game,
				},
			},
		}: StateTree) => ({
			guestName,
			guestBio,
			guestSocialHandle,
			guestSocialHyperlink,
			guestImageWithName,
			guestImageWithoutName,
			hostName,
			hostSocialHandle,
			hostSocialHyperlink,
			hostImageWithoutName,
			game,
		}),
	)
	const dispatch = useAppDispatch()
	const triggerDispatchValidation = useCallback(
		(props: PosterInfoStateValidProps) => {
			dispatch(isValidNextPosterInfoState(props))
		},
		[dispatch],
	)

	return validateFields(
		{
			guestName,
			guestBio,
			guestSocialHandle,
			guestSocialHyperlink,
			guestImageWithName,
			guestImageWithoutName,
			hostName,
			hostSocialHandle,
			hostSocialHyperlink,
			hostImageWithoutName,
			game,
		},
		triggerDispatchValidation,
	)
}
