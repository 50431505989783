import type { OnDemandVideoStateActions } from '../interfaces/OnDemandVideoActions'
import type { VideoState } from '../interfaces/VideoState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'
import { initialState, videoUrlInitialState, guestScoreInitialState } from './videoState'

export default function onDemandVideoState(state = initialState, action: OnDemandVideoStateActions): VideoState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.ON_DEMAND_VIDEO_STATE_UPDATE: {
			const { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore } = action.props
			return {
				highQualityVideoUrl: { ...videoUrlInitialState, value: highQualityVideoUrl },
				videoUrl: { ...videoUrlInitialState, value: videoUrl },
				lowQualityVideoUrl: { ...videoUrlInitialState, value: lowQualityVideoUrl },
				guestScore: { ...guestScoreInitialState, value: guestScore },
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.ON_DEMAND_VIDEO_STATE_VALID: {
			const { isGuestScoreValid, isHighQualityVideoUrlValid, isVideoUrlValid, isLowQualityVideoUrlValid } = action.props
			return {
				highQualityVideoUrl: { ...state.highQualityVideoUrl, isValid: isHighQualityVideoUrlValid },
				videoUrl: { ...state.videoUrl, isValid: isVideoUrlValid },
				lowQualityVideoUrl: { ...state.lowQualityVideoUrl, isValid: isLowQualityVideoUrlValid },
				guestScore: { ...state.guestScore, isValid: isGuestScoreValid },
			}
		}
		default:
			return state
	}
}
