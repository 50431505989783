import type { UpdateNextPosterInfoActions } from '../interfaces/actions/UpdateNextPosterInfoActions'
import type { UpdatePosterInfoState } from '../interfaces/reducers/UpdatePosterInfoState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'
import { initialState } from './updatePosterInfo'

export default function updateNextPosterInfo(state = initialState, action: UpdateNextPosterInfoActions): UpdatePosterInfoState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEXT_POSTER_INFO_COMMAND_START:
			return { ...initialState, isUpdating: true }
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEXT_POSTER_INFO_COMMAND_FAILURE:
			console.error('UPDATE_NEXT_POSTER_INFO_COMMAND_FAILURE')
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEXT_POSTER_INFO_COMMAND_SUCCESS:
			console.log('UPDATE_NEXT_POSTER_INFO_COMMAND_SUCCESS')
			return initialState
		default:
			return state
	}
}
