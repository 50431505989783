import { useCallback } from 'react'
import { onDemandVideoStateValid } from '../../../redux/dashboardInputs/actions/onDemandVideoState'
import type { VideoStateValidActionProps } from '../../../redux/dashboardInputs/interfaces/VideoStateValidActionProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'
import { validateFields } from './useCountdownShowValidation'

export const useOnDemandVideoGuestScoreValidation = (): (() => Promise<boolean>) => {
	const { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore } = useAppSelector(
		({
			dashboardInputs: {
				onDemandVideo: { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore },
			},
		}: StateTree) => ({ highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore }),
	)
	const dispatch = useAppDispatch()
	const triggerDispatchValidation = useCallback(
		(props: VideoStateValidActionProps) => {
			dispatch(onDemandVideoStateValid(props))
		},
		[dispatch],
	)

	return validateFields({ highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore }, triggerDispatchValidation)
}
