import type { FunctionComponent, ReactElement } from 'react'
import { CardBody, CardFieldSet, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import { maxMinuteSecondNumber, minTimeNumber } from '../../utils/hooks/dashboardInputs/useTimestampAutomationSettings'
import type { TimestampProps } from '../../redux/dashboardInputs/interfaces/TimestampProps'
import type { ChangeEvent } from 'react'

type Props<T extends string> = {
	title: string
	timestampsSettings: Record<T, TimestampProps>
	updateTimestampsSettings: (command: T) => (event: ChangeEvent<HTMLInputElement>) => void
	isValid: boolean
	commandTitles: {
		[key: string]: string
	}
}

function TimestampsAutomation<T extends string>({
	title,
	timestampsSettings,
	updateTimestampsSettings,
	isValid,
	commandTitles,
}: Props<T>): ReactElement<typeof CardWrapper, FunctionComponent<typeof CardWrapper>> {
	return (
		<CardWrapper isValid={isValid}>
			<CardHeader>
				<CardHeading>{title}</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<table style={{ margin: '0 auto' }}>
						<thead>
							<tr>
								<th>Command</th>
								<th>Minute</th>
								<th>Second</th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(timestampsSettings).map(([keyProperty, item], index) => {
								const commandName = keyProperty
								const title = commandTitles[commandName]
								const onChangeFunction = updateTimestampsSettings(commandName as T)
								const { second, minute } = item as TimestampProps

								return (
									<tr key={`${commandName}-${index}`}>
										<td>{title}</td>
										<td>
											<input
												type="number"
												value={minute}
												name="minute"
												min={minTimeNumber}
												max={maxMinuteSecondNumber}
												onChange={onChangeFunction}
											/>
										</td>
										<td>
											<input
												type="number"
												value={second}
												name="second"
												min={minTimeNumber}
												max={maxMinuteSecondNumber}
												onChange={onChangeFunction}
											/>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default TimestampsAutomation
