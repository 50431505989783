import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { StateTree } from '../../interfaces'
import type { UpdatePosterInfoFailureAction } from '../interfaces/actions/UpdatePosterInfoFailureAction'
import type { UpdatePosterInfoStartAction } from '../interfaces/actions/UpdatePosterInfoStartAction'
import type { UpdatePosterInfoSuccessAction } from '../interfaces/actions/UpdatePosterInfoSuccessAction'
import type { UpdateMainMenuContentCommand } from '../interfaces/UpdateMainMenuContent'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

export const updatePosterInfoStart = (): UpdatePosterInfoStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_POSTER_INFO_COMMAND_START }
}

export const updatePosterInfoFailure = (): UpdatePosterInfoFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_POSTER_INFO_COMMAND_FAILURE }
}

const updatePosterInfoSuccess = (): UpdatePosterInfoSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_POSTER_INFO_COMMAND_SUCCESS }
}

export default function updatePosterInfo() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(updatePosterInfoStart())

		try {
			const {
				dashboardInputs: {
					posterInfo: {
						guestName: { value: guestName },
						guestBio: { value: guestBio },
						guestSocialHandle: { value: guestSocialHandle },
						guestSocialHyperlink: { value: guestSocialHyperlink },
						guestImageWithName: { value: guestImageWithName },
						guestImageWithoutName: { value: guestImageWithoutName },
						hostName: { value: hostName },
						hostSocialHandle: { value: hostSocialHandle },
						hostSocialHyperlink: { value: hostSocialHyperlink },
						hostImageWithoutName: { value: hostImageWithoutName },
						game: { value: gameName },
					},
				},
			} = getState()

			const posterData: Omit<UpdateMainMenuContentCommand, 'NextGuest'> = {
				command: 'updateMainMenuContent',
				Guest: {
					guest: {
						name: guestName,
						bio: guestBio,
						socialHandle: guestSocialHandle,
						socialHyperlink: guestSocialHyperlink,
						imageWithName: guestImageWithName,
						imageWithoutName: guestImageWithoutName,
					},
					host: {
						name: hostName,
						socialHandle: hostSocialHandle,
						socialHyperlink: hostSocialHyperlink,
						imageWithoutName: hostImageWithoutName,
					},
					gameName,
				},
			}
			await sendCommandToStudio(posterData)

			dispatch(updatePosterInfoSuccess())
		} catch {
			dispatch(updatePosterInfoFailure())
		}
	}
}
