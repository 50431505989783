import { standardVideoUrl } from '../../../config/app'
import type { VideoStateActions } from '../interfaces/VideoActions'
import type { VideoState } from '../interfaces/VideoState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

export const videoUrlInitialState = {
	value: standardVideoUrl || '',
	isValid: true,
}
export const guestScoreInitialState = {
	value: 54321,
	isValid: true,
}

export const initialState: VideoState = {
	highQualityVideoUrl: { ...videoUrlInitialState },
	videoUrl: { ...videoUrlInitialState },
	lowQualityVideoUrl: { ...videoUrlInitialState },
	guestScore: guestScoreInitialState,
}

export default function videoState(state = initialState, action: VideoStateActions): VideoState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.VIDEO_STATE_UPDATE: {
			const { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore } = action.props
			return {
				highQualityVideoUrl: { ...videoUrlInitialState, value: highQualityVideoUrl },
				videoUrl: { ...videoUrlInitialState, value: videoUrl },
				lowQualityVideoUrl: { ...videoUrlInitialState, value: lowQualityVideoUrl },
				guestScore: { ...guestScoreInitialState, value: guestScore },
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.VIDEO_STATE_VALID: {
			const { isGuestScoreValid, isHighQualityVideoUrlValid, isVideoUrlValid, isLowQualityVideoUrlValid } = action.props
			return {
				highQualityVideoUrl: { ...state.highQualityVideoUrl, isValid: isHighQualityVideoUrlValid },
				videoUrl: { ...state.videoUrl, isValid: isVideoUrlValid },
				lowQualityVideoUrl: { ...state.lowQualityVideoUrl, isValid: isLowQualityVideoUrlValid },
				guestScore: { ...state.guestScore, isValid: isGuestScoreValid },
			}
		}
		default:
			return state
	}
}
