import type { NextPosterInfoActions } from '../interfaces/NextPosterInfoActions'
import type { PosterInfoState } from '../interfaces/PosterInfoState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'
import { posterInfoPropsInitialState } from './posterInfo'

export const initialState: PosterInfoState = {
	guestName: {
		value: '',
		isValid: true,
	},
	guestBio: {
		value: '',
		isValid: true,
	},
	guestSocialHandle: {
		value: '',
		isValid: true,
	},
	guestSocialHyperlink: {
		value: '',
		isValid: true,
	},
	guestImageWithName: {
		value: '',
		isValid: true,
	},
	guestImageWithoutName: {
		value: '',
		isValid: true,
	},
	hostName: {
		value: '',
		isValid: true,
	},
	hostSocialHandle: {
		value: '',
		isValid: true,
	},
	hostSocialHyperlink: {
		value: '',
		isValid: true,
	},
	hostImageWithoutName: {
		value: '',
		isValid: true,
	},
	game: {
		value: 'Beat',
		isValid: true,
	},
}

export default function nextPosterInfo(state = initialState, action: NextPosterInfoActions): PosterInfoState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_POSTER_INFO_STATE_UPDATE: {
			const {
				guestName,
				guestBio,
				guestSocialHandle,
				guestSocialHyperlink,
				guestImageWithName,
				guestImageWithoutName,
				hostName,
				hostSocialHandle,
				hostSocialHyperlink,
				hostImageWithoutName,
				game,
			} = action.props
			return {
				...initialState,
				guestName: {
					...posterInfoPropsInitialState,
					value: guestName,
				},
				guestBio: {
					...posterInfoPropsInitialState,
					value: guestBio,
				},
				guestSocialHandle: {
					...posterInfoPropsInitialState,
					value: guestSocialHandle,
				},
				guestSocialHyperlink: {
					...posterInfoPropsInitialState,
					value: guestSocialHyperlink,
				},
				guestImageWithName: {
					...posterInfoPropsInitialState,
					value: guestImageWithName,
				},
				guestImageWithoutName: {
					...posterInfoPropsInitialState,
					value: guestImageWithoutName,
				},
				hostName: {
					...posterInfoPropsInitialState,
					value: hostName,
				},
				hostSocialHandle: {
					...posterInfoPropsInitialState,
					value: hostSocialHandle,
				},
				hostSocialHyperlink: {
					...posterInfoPropsInitialState,
					value: hostSocialHyperlink,
				},
				hostImageWithoutName: {
					...posterInfoPropsInitialState,
					value: hostImageWithoutName,
				},
				game: {
					...posterInfoPropsInitialState,
					value: game,
				},
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_POSTER_INFO_STATE_VALID: {
			const {
				isGuestNameValid,
				isGuestBioValid,
				isGuestSocialHandleValid,
				isGuestSocialHyperlinkValid,
				isGuestImageWithNameValid,
				isGuestImageWithoutNameValid,
				isHostNameValid,
				isHostSocialHandleValid,
				isHostSocialHyperlinkValid,
				isHostImageWithoutNameValid,
				isGameValid,
			} = action.props
			return {
				...initialState,
				guestName: {
					value: state.guestName.value,
					isValid: isGuestNameValid,
				},
				guestBio: {
					value: state.guestBio.value,
					isValid: isGuestBioValid,
				},
				guestSocialHandle: {
					value: state.guestSocialHandle.value,
					isValid: isGuestSocialHandleValid,
				},
				guestSocialHyperlink: {
					value: state.guestSocialHyperlink.value,
					isValid: isGuestSocialHyperlinkValid,
				},
				guestImageWithName: {
					value: state.guestImageWithName.value,
					isValid: isGuestImageWithNameValid,
				},
				guestImageWithoutName: {
					value: state.guestImageWithoutName.value,
					isValid: isGuestImageWithoutNameValid,
				},
				hostName: {
					value: state.hostName.value,
					isValid: isHostNameValid,
				},
				hostSocialHandle: {
					value: state.hostSocialHandle.value,
					isValid: isHostSocialHandleValid,
				},
				hostSocialHyperlink: {
					value: state.hostSocialHyperlink.value,
					isValid: isHostSocialHyperlinkValid,
				},
				hostImageWithoutName: {
					value: state.hostImageWithoutName.value,
					isValid: isHostImageWithoutNameValid,
				},
				game: {
					value: state.game.value,
					isValid: isGameValid,
				},
			}
		}
		default:
			return state
	}
}
