import { useCallback } from 'react'
import { isValidPosterInfoState } from '../../../redux/dashboardInputs/actions/posterInfoState'
import type { PosterInfoStateValidProps } from '../../../redux/dashboardInputs/interfaces/PosterInfoStateValidProps'
import type { StateTree } from '../../../redux/interfaces'
import { isURLFileValid } from '../../validation/isURLFileValid'
import { isURLValid } from '../../validation/isURLValid'
import { isValueValid } from '../../validation/isValueValid'
import { useAppDispatch, useAppSelector } from '..'
import { PosterInfoPropertyState } from '../../../redux/dashboardInputs/interfaces/PosterInfoPropertyState'

type Fields = {
	guestName: PosterInfoPropertyState
	guestBio: PosterInfoPropertyState
	guestSocialHandle: PosterInfoPropertyState
	guestSocialHyperlink: PosterInfoPropertyState
	guestImageWithName: PosterInfoPropertyState
	guestImageWithoutName: PosterInfoPropertyState
	hostName: PosterInfoPropertyState
	hostSocialHandle: PosterInfoPropertyState
	hostSocialHyperlink: PosterInfoPropertyState
	hostImageWithoutName: PosterInfoPropertyState
	game: PosterInfoPropertyState
}

export const validateFields =
	(
		{
			guestName,
			guestBio,
			guestSocialHandle,
			guestSocialHyperlink,
			guestImageWithName,
			guestImageWithoutName,
			hostName,
			hostSocialHandle,
			hostSocialHyperlink,
			hostImageWithoutName,
			game,
		}: Fields,
		triggerDispatchValidation: (props: PosterInfoStateValidProps) => void,
	) =>
	async () => {
		const promisesFetch = [
			isURLFileValid(guestImageWithName.value),
			isURLFileValid(guestImageWithoutName.value),
			isURLFileValid(hostImageWithoutName.value),
		]
		const [isGuestImageWithNameValid, isGuestImageWithoutNameValid, isHostImageWithoutNameValid] = await Promise.all(promisesFetch)

		const isGuestNameValid = isValueValid(guestName)
		const isGuestBioValid = isValueValid(guestBio)
		const isGuestSocialHandleValid = isValueValid(guestSocialHandle)
		const isGuestSocialHyperlinkValid = isURLValid(guestSocialHyperlink.value)
		const isHostNameValid = isValueValid(hostName)
		const isHostSocialHandleValid = isValueValid(hostSocialHandle)
		const isHostSocialHyperlinkValid = isURLValid(hostSocialHyperlink.value)
		const isGameValid = isValueValid(game)

		if (
			isGuestNameValid &&
			isGuestBioValid &&
			isGuestSocialHandleValid &&
			isGuestSocialHyperlinkValid &&
			isGuestImageWithNameValid &&
			isGuestImageWithoutNameValid &&
			isHostNameValid &&
			isHostSocialHandleValid &&
			isHostSocialHyperlinkValid &&
			isHostImageWithoutNameValid &&
			isGameValid
		) {
			return true
		}

		triggerDispatchValidation({
			isGuestNameValid,
			isGuestBioValid,
			isGuestSocialHandleValid,
			isGuestSocialHyperlinkValid,
			isGuestImageWithNameValid,
			isGuestImageWithoutNameValid,
			isHostNameValid,
			isHostSocialHandleValid,
			isHostSocialHyperlinkValid,
			isHostImageWithoutNameValid,
			isGameValid,
		})

		return false
	}

export const usePosterInfoValidation = (): (() => Promise<boolean>) => {
	const {
		guestName,
		guestBio,
		guestSocialHandle,
		guestSocialHyperlink,
		guestImageWithName,
		guestImageWithoutName,
		hostName,
		hostSocialHandle,
		hostSocialHyperlink,
		hostImageWithoutName,
		game,
	} = useAppSelector(
		({
			dashboardInputs: {
				posterInfo: {
					guestName,
					guestBio,
					guestSocialHandle,
					guestSocialHyperlink,
					guestImageWithName,
					guestImageWithoutName,
					hostName,
					hostSocialHandle,
					hostSocialHyperlink,
					hostImageWithoutName,
					game,
				},
			},
		}: StateTree) => ({
			guestName,
			guestBio,
			guestSocialHandle,
			guestSocialHyperlink,
			guestImageWithName,
			guestImageWithoutName,
			hostName,
			hostSocialHandle,
			hostSocialHyperlink,
			hostImageWithoutName,
			game,
		}),
	)
	const dispatch = useAppDispatch()
	const triggerDispatchValidation = useCallback(
		(props: PosterInfoStateValidProps) => {
			dispatch(isValidPosterInfoState(props))
		},
		[dispatch],
	)

	return validateFields(
		{
			guestName,
			guestBio,
			guestSocialHandle,
			guestSocialHyperlink,
			guestImageWithName,
			guestImageWithoutName,
			hostName,
			hostSocialHandle,
			hostSocialHyperlink,
			hostImageWithoutName,
			game,
		},
		triggerDispatchValidation,
	)
}
