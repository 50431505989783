import type { ShowLuckyWinnersActions } from '../interfaces/actions/ShowLuckyWinnersActions'
import type { CommandState } from '../interfaces/CommandState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: CommandState = {
	isLoading: false,
	isAvailable: true,
}

export default function showLuckyWinners(state = initialState, action: ShowLuckyWinnersActions): CommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.SHOW_LUCKY_WINNERS_START:
			return { ...state, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.SHOW_LUCKY_WINNERS_FAILURE:
			console.error('SHOW_LUCKY_WINNERS_FAILURE')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.SHOW_LUCKY_WINNERS_SUCCESS:
			console.log('SHOW_LUCKY_WINNERS_SUCCESS')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.SHOW_LUCKY_WINNERS_TOGGLE:
			return { ...state, isAvailable: action.isAvailable }
		default:
			return state
	}
}
