import { OnDemandTimestampCommand } from '../../../config/enums'
import type { TimestampProps } from '../interfaces/TimestampProps'
import type { OnDemandTimestampsSettingsActions, OnDemandTimestampsState } from '../interfaces/OnDemandTimestamps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'
import { initialSettingsState } from './timestampsAutomationSettingsState'

export const initialState: OnDemandTimestampsState = {
	value: Object.fromEntries(Object.values(OnDemandTimestampCommand).map((commandName) => [commandName, initialSettingsState])) as Record<
		OnDemandTimestampCommand,
		TimestampProps
	>,
	isValid: true,
}

export default function onDemandTimestampsSettingsState(state = initialState, action: OnDemandTimestampsSettingsActions): OnDemandTimestampsState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.ON_DEMAND_TIMESTAMPS_SETTINGS_STATE_UPDATE: {
			const { isValid, value } = state

			return {
				...state,
				isValid,
				value: { ...value, ...action.props },
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.ON_DEMAND_TIMESTAMPS_SETTINGS_STATE_VALID: {
			const { value } = state

			return {
				...state,
				...value,
				isValid: action.props.isValid,
			}
		}
		default:
			return state
	}
}
