import type { ChangeEvent } from 'react'
import { useCallback } from 'react'
import type { OnDemandTimestampCommand } from '../../../config/enums'
import onDemandTimestampsSettingsStateUpdate from '../../../redux/dashboardInputs/actions/onDemandTimestampsSettingsState'
import type { TimestampProps } from '../../../redux/dashboardInputs/interfaces/TimestampProps'
import type { OnDemandTimestampsState } from '../../../redux/dashboardInputs/interfaces/OnDemandTimestamps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'
import { onTimestampSettingChange } from './useTimestampAutomationSettings'

type UseOnDemandTimestampSettings = [
	OnDemandTimestampsState,
	(timestampCommand: OnDemandTimestampCommand) => (event: ChangeEvent<HTMLInputElement>) => void,
	(props: Record<OnDemandTimestampCommand, TimestampProps>) => void,
]

export const useOnDemandTimestampSettings = (): UseOnDemandTimestampSettings => {
	const { onDemandTimestampsSettings, isValid } = useAppSelector(
		({
			dashboardInputs: {
				onDemandTimestampsSettings: { value: onDemandTimestampsSettings, isValid },
			},
		}: StateTree) => ({ onDemandTimestampsSettings, isValid }),
	)

	const dispatch = useAppDispatch()
	const updateOnDemandTimestampSettingsState = useCallback(
		(props: Record<OnDemandTimestampCommand, TimestampProps>) => onDemandTimestampsSettingsStateUpdate(props)(dispatch),
		[dispatch],
	)

	return [
		{ value: onDemandTimestampsSettings, isValid },
		onTimestampSettingChange(onDemandTimestampsSettings, updateOnDemandTimestampSettingsState),
		updateOnDemandTimestampSettingsState,
	]
}
