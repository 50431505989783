import {
	isProduction,
	nodeEnv,
	proxyApiUrl,
	REACT_APP_COMMAND_ENDPOINT,
	REACT_APP_DOMAIN,
	REACT_APP_JWT_ENDPOINT,
	REACT_APP_MODE,
	REACT_APP_AFTER_START_GAME_TIME_SECONDS,
	REACT_APP_AFTER_START_GAME_GRACE_PERIOD,
} from './api'

const appEnv = REACT_APP_MODE
const jwtEndpoint = REACT_APP_JWT_ENDPOINT
const studioCommandEndPoint = REACT_APP_COMMAND_ENDPOINT
const appDomain = REACT_APP_DOMAIN

// Define the minimum number of seconds between start game and pick winners
// Value is composed of three parts:
// AFTER_START_GAME_TIME_SECONDS the amount of time ClientAPI waits before scores are no longer accepted
// AFTER_START_GAME_GRACE_PERIOD a grace period to allow scores to be processed (defaults to 20 seconds)
// pickWinnersAllowance the amount of time to allow for pick winners to run (constant amount)
const pickWinnersAllowance = 10 //seconds
const minSecondsBetweenStartGameAndDisplayWinners =
	Number(REACT_APP_AFTER_START_GAME_TIME_SECONDS) + Number(REACT_APP_AFTER_START_GAME_GRACE_PERIOD || 20) + pickWinnersAllowance

export { appDomain, appEnv, isProduction, jwtEndpoint, minSecondsBetweenStartGameAndDisplayWinners, nodeEnv, proxyApiUrl, studioCommandEndPoint }
