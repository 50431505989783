import type { Dispatch } from 'redux'
import type { PosterInfoStateValidAction } from '../interfaces/PosterInfoStateValidAction'
import type { PosterInfoStateValidProps } from '../interfaces/PosterInfoStateValidProps'
import type { UpdatePosterInfoStateAction } from '../interfaces/UpdatePosterInfoStateAction'
import type { UpdatePosterInfoStateProps } from '../interfaces/UpdatePosterInfoStateProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

export const updatePosterInfoState = (props: UpdatePosterInfoStateProps): UpdatePosterInfoStateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.POSTER_INFO_STATE_UPDATE,
		props,
	}
}

export const isValidPosterInfoState = (props: PosterInfoStateValidProps): PosterInfoStateValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.POSTER_INFO_STATE_VALID,
		props,
	}
}

export default function posterInfoState(props: UpdatePosterInfoStateProps) {
	return (dispatch: Dispatch): void => {
		dispatch(updatePosterInfoState(props))
	}
}
