import type { Dispatch } from 'redux'
import { OnDemandTimestampCommand } from '../../../config/enums'
import type { TimestampProps } from '../interfaces/TimestampProps'
import type { OnDemandTimestampsSettingsUpdateAction, OnDemandTimestampsSettingsValidAction } from '../interfaces/OnDemandTimestamps'
import type { TimestampsAutomationSettingsValidActionProps } from '../interfaces/TimestampsAutomation'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const onDemandTimestampsSettingsStateUpdate = (props: Record<OnDemandTimestampCommand, TimestampProps>): OnDemandTimestampsSettingsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.ON_DEMAND_TIMESTAMPS_SETTINGS_STATE_UPDATE,
		props,
	}
}

export const onDemandTimestampsSettingsStateValid = (props: TimestampsAutomationSettingsValidActionProps): OnDemandTimestampsSettingsValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.ON_DEMAND_TIMESTAMPS_SETTINGS_STATE_VALID,
		props,
	}
}

export default function onDemandTimestampsSettingsState(props: Record<OnDemandTimestampCommand, TimestampProps>) {
	return (dispatch: Dispatch): void => {
		dispatch(onDemandTimestampsSettingsStateUpdate(props))
	}
}
