import { analyticsCuePointTitles, timestampAutomationCommandTitles, onDemandTimestampCommandTitles } from '../config/app'
import { Game } from '../config/enums'
import type { StateTree } from '../redux/interfaces'
import type { ShowInformation } from '../redux/studioCommand/interfaces/ShowInformation'
import { parseBaseGameSettingsValues, parseBlockbusterSettingsValues } from './getGameSettingsValues'
import { getNextGoals } from './getNextGoals'
import { getNextShowTime } from './getNextShowTime'

export const getShowInformation = (stateTree: StateTree): ShowInformation => {
	const {
		dashboardInputs: {
			video,
			onDemandVideo,
			beatGuestMessages,
			prizes,
			blockbusterSettings,
			centipedeSettings,
			beatSettings,
			rallySettings,
			breakoutSettings,
			featuredGame,
			nextDailyGoals,
			nextWeeklyGoals,
			nextMonthlyGoals,
			timestampsAutomationSettings,
			onDemandTimestampsSettings,
			analyticsCuePointsState: { props: analyticsCuePoints },
			posterInfo,
			nextPosterInfo,
			seasonWinnerState,
		},
		commands: {
			leaguesReset: { leaguesResetStatus: leaguesReset },
			seasonTransition: { seasonTransitionStatus: seasonTransition },
			showType: { showType },
		},
		showCalendar: { selectedDate },
	} = stateTree

	const {
		yesFirst: { value: yesFirst },
		yesSecond: { value: yesSecond },
		yesOnDemand: { value: yesOnDemand },
		noFirst: { value: noFirst },
		noSecond: { value: noSecond },
	} = beatGuestMessages
	const {
		currency,
		beatGuestWinner: { value: beatGuestWinner },
		luckyWinner: { value: luckyWinner },
		coLuckyWinner: { value: coLuckyWinner },
		leagueWinner: { value: leagueWinner },
	} = prizes

	return {
		video: {
			highQualityVideoUrl: video.highQualityVideoUrl.value,
			videoUrl: video.videoUrl.value,
			lowQualityVideoUrl: video.lowQualityVideoUrl.value,
			guestScore: video.guestScore.value,
		},
		onDemandVideo: {
			highQualityVideoUrl: onDemandVideo.highQualityVideoUrl.value,
			videoUrl: onDemandVideo.videoUrl.value,
			lowQualityVideoUrl: onDemandVideo.lowQualityVideoUrl.value,
			guestScore: onDemandVideo.guestScore.value,
		},
		beatGuestMessages: { yesFirst, yesSecond, yesOnDemand, noFirst, noSecond },
		prizes: { currency, beatGuestWinner, luckyWinner, coLuckyWinner, leagueWinner },
		gameSettings: {
			[Game.Blockbuster]: parseBlockbusterSettingsValues(blockbusterSettings),
			[Game.Centipede]: parseBaseGameSettingsValues(centipedeSettings, Game.Centipede),
			[Game.Beat]: parseBaseGameSettingsValues(beatSettings, Game.Beat),
			[Game.Rally]: parseBaseGameSettingsValues(rallySettings, Game.Rally),
			[Game.Breakout]: parseBaseGameSettingsValues(breakoutSettings, Game.Breakout),
		},
		gameSettingsInputs: {
			[Game.Blockbuster]: {
				shots: blockbusterSettings.shots.value,
				level: blockbusterSettings.level.value,
				stage: blockbusterSettings.stage.value,
			},
			[Game.Centipede]: {
				level: centipedeSettings.level.value,
				stage: centipedeSettings.stage.value,
			},
			[Game.Beat]: {
				level: beatSettings.level.value,
				stage: beatSettings.stage.value,
			},
			[Game.Rally]: {
				level: rallySettings.level.value,
				stage: rallySettings.stage.value,
			},
			[Game.Breakout]: {
				level: breakoutSettings.level.value,
				stage: breakoutSettings.stage.value,
			},
		},
		featuredGame,
		nextGoals: getNextGoals({ nextDailyGoals, nextWeeklyGoals, nextMonthlyGoals }),
		nextGoalsInputs: {
			nextDailyGoals: nextDailyGoals.value,
			nextWeeklyGoals: nextWeeklyGoals.reset ? nextWeeklyGoals.value : undefined,
			nextMonthlyGoals: nextMonthlyGoals.reset ? nextMonthlyGoals.value : undefined,
		},
		nextShowTimeStamp: getNextShowTime(selectedDate, true).valueOf(),
		timestampAutomationSettings: timestampsAutomationSettings.value,
		timestampAutomationCommandTitles,
		onDemandTimestampsSettings: onDemandTimestampsSettings.value,
		onDemandTimestampCommandTitles,
		analyticsCuePoints,
		analyticsCuePointTitles,
		nextGuestPosterInfo: {
			Guest: {
				guest: {
					name: posterInfo.guestName.value,
					bio: posterInfo.guestBio.value,
					socialHandle: posterInfo.guestSocialHandle.value,
					socialHyperlink: posterInfo.guestSocialHyperlink.value,
					imageWithName: posterInfo.guestImageWithName.value,
					imageWithoutName: posterInfo.guestImageWithoutName.value,
				},
				host: {
					name: posterInfo.hostName.value,
					socialHandle: posterInfo.hostSocialHandle.value,
					socialHyperlink: posterInfo.hostSocialHyperlink.value,
					imageWithoutName: posterInfo.hostImageWithoutName.value,
				},
				gameName: posterInfo.game.value,
			},
			NextGuest: {
				guest: {
					name: nextPosterInfo.guestName.value,
					bio: nextPosterInfo.guestBio.value,
					socialHandle: nextPosterInfo.guestSocialHandle.value,
					socialHyperlink: nextPosterInfo.guestSocialHyperlink.value,
					imageWithName: nextPosterInfo.guestImageWithName.value,
					imageWithoutName: nextPosterInfo.guestImageWithName.value,
				},
				host: {
					name: nextPosterInfo.hostName.value,
					socialHandle: nextPosterInfo.hostSocialHandle.value,
					socialHyperlink: nextPosterInfo.hostSocialHyperlink.value,
					imageWithoutName: nextPosterInfo.hostImageWithoutName.value,
				},
				gameName: nextPosterInfo.game.value,
			},
		},
		leaguesReset,
		seasonTransition,
		showType,
		seasonWinnerUserId: seasonTransition ? seasonWinnerState.userId : '',
	}
}
