import type { UpdatePosterInfoActions } from '../interfaces/actions/UpdatePosterInfoActions'
import type { UpdatePosterInfoState } from '../interfaces/reducers/UpdatePosterInfoState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

export const initialState: UpdatePosterInfoState = {
	isUpdating: false,
}

export default function updatePosterInfo(state = initialState, action: UpdatePosterInfoActions): UpdatePosterInfoState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_POSTER_INFO_COMMAND_START:
			return { ...initialState, isUpdating: true }
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_POSTER_INFO_COMMAND_FAILURE:
			console.error('UPDATE_POSTER_INFO_COMMAND_FAILURE')
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_POSTER_INFO_COMMAND_SUCCESS:
			console.log('UPDATE_POSTER_INFO_COMMAND_SUCCESS')
			return initialState
		default:
			return state
	}
}
