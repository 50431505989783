import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { StateTree } from '../../interfaces'
import type { DisplayBeatGuestFailureAction } from '../interfaces/actions/DisplayBeatGuestFailureAction'
import type { DisplayBeatGuestStartAction } from '../interfaces/actions/DisplayBeatGuestStartAction'
import type { DisplayBeatGuestSuccessAction } from '../interfaces/actions/DisplayBeatGuestSuccessAction'
import type { DisplayBeatGuest } from '../interfaces/DisplayBeatGuest'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const displayBeatGuestStart = (): DisplayBeatGuestStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.DISPLAY_BEAT_GUEST_START }
}

const displayBeatGuestFailure = (): DisplayBeatGuestFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.DISPLAY_BEAT_GUEST_FAILURE }
}

const displayBeatGuestSuccess = (): DisplayBeatGuestSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.DISPLAY_BEAT_GUEST_SUCCESS }
}

export default function displayBeatGuest() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(displayBeatGuestStart())

		try {
			const {
				dashboardInputs: {
					beatGuestMessages: { yesFirst, yesSecond, yesOnDemand, noFirst, noSecond },
				},
			} = getState()

			const displayBeatGuestCommand: DisplayBeatGuest = {
				command: 'displayGuestCompeteResults',
				beatGuestTitle: yesFirst.value,
				beatGuestMessage: yesSecond.value,
				beatGuestOnDemandMessage: yesOnDemand.value,
				lostToGuestTitle: noFirst.value,
				lostToGuestMessage: noSecond.value,
			}
			await sendCommandToStudio<string>(displayBeatGuestCommand)

			dispatch(displayBeatGuestSuccess())
		} catch {
			dispatch(displayBeatGuestFailure())
		}
	}
}
