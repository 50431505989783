import type { Dispatch } from 'redux'
import type { ShowLuckyWinnersToggleAction } from '../interfaces/actions/ShowLuckyWinnersToggleAction'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const showLuckyWinnersToggle = (isAvailable: boolean): ShowLuckyWinnersToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SHOW_LUCKY_WINNERS_TOGGLE, isAvailable }
}

export default function toggleShowLuckyWinners(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(showLuckyWinnersToggle(isAvailable))
	}
}
