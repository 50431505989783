import type { StartPartBGameActions } from '../interfaces/actions/StartPartBGameActions'
import type { CommandState } from '../interfaces/CommandState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: CommandState = {
	isLoading: false,
	isAvailable: true,
}

export default function startPartBGame(state = initialState, action: StartPartBGameActions): CommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.START_PART_B_GAME_START:
			return { ...state, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.START_PART_B_GAME_FAILURE:
			console.error('START_PART_B_GAME_FAILURE')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.START_PART_B_GAME_SUCCESS:
			console.log('START_PART_B_GAME_SUCCESS')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.START_PART_B_GAME_TOGGLE:
			return { ...state, isAvailable: action.isAvailable }
		default:
			return state
	}
}
