import type { BeatGuestMessagesActions } from '../interfaces/BeatGuestMessagesActions'
import type { BeatGuestMessagesState } from '../interfaces/BeatGuestMessagesState'
import type { BeatGuestMessageState } from '../interfaces/BeatGuestMessageState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const beatGuestMessageInitialState: BeatGuestMessageState = {
	value: '',
	isValid: true,
}

const initialState: BeatGuestMessagesState = {
	yesFirst: { value: 'You beat the guest!', isValid: true },
	yesSecond: { value: 'You won 10 Tokens!', isValid: true },
	yesOnDemand: { value: '', isValid: true },
	noFirst: { value: 'The guest beat you!', isValid: true },
	noSecond: { value: 'Better luck next time!', isValid: true },
}

export default function beatGuestMessages(state = initialState, action: BeatGuestMessagesActions): BeatGuestMessagesState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.BEAT_GUEST_MESSAGES_STATE_UPDATE: {
			const { yesFirst, yesSecond, yesOnDemand, noFirst, noSecond } = action.props
			return {
				...initialState,
				yesFirst: { ...beatGuestMessageInitialState, value: yesFirst },
				yesSecond: { ...beatGuestMessageInitialState, value: yesSecond },
				yesOnDemand: { ...beatGuestMessageInitialState, value: yesOnDemand },
				noFirst: { ...beatGuestMessageInitialState, value: noFirst },
				noSecond: { ...beatGuestMessageInitialState, value: noSecond },
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.BEAT_GUEST_MESSAGES_STATE_VALID: {
			const { isYesSecondValid, isYesOnDemandValid, isYesFirstValid, isNoFirstValid, isNoSecondValid } = action.props
			return {
				...initialState,
				yesFirst: {
					value: state.yesFirst.value,
					isValid: isYesFirstValid,
				},
				yesSecond: {
					value: state.yesSecond.value,
					isValid: isYesSecondValid,
				},
				yesOnDemand: {
					value: state.yesOnDemand.value,
					isValid: isYesOnDemandValid,
				},
				noFirst: { value: state.noFirst.value, isValid: isNoFirstValid },
				noSecond: { value: state.noSecond.value, isValid: isNoSecondValid },
			}
		}
		default:
			return state
	}
}
