import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import showLuckyWinners from '../../../redux/studioCommand/actions/showLuckyWinners'
import toggleShowLuckyWinners from '../../../redux/studioCommand/actions/toggleShowLuckyWinners'
import { useAppDispatch, useAppSelector } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'

type UseShowLuckyWinners = [PromiseVoid, ToggleCommand]

export default function useShowLuckyWinners(): UseShowLuckyWinners {
	const dispatch = useAppDispatch()
	const isAvailable = useAppSelector(
		({
			wsMessage: {
				showLuckyWinners: { isAvailable },
			},
		}: StateTree) => isAvailable,
	)
	const toggleShowLuckyWinnersButton = useCallback((isAvailable: boolean) => toggleShowLuckyWinners(isAvailable)(dispatch), [dispatch])
	const sendShowLuckyWinners = useCallback(() => showLuckyWinners()(dispatch), [dispatch])

	useEffect(() => {
		toggleShowLuckyWinnersButton(isAvailable)
	}, [isAvailable, toggleShowLuckyWinnersButton])

	return [sendShowLuckyWinners, toggleShowLuckyWinnersButton]
}
