import { useCallback } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import updatePosterInfo, { updatePosterInfoFailure, updatePosterInfoStart } from '../../../redux/studioCommand/actions/updatePosterInfo'
import type { UpdatePosterInfoState } from '../../../redux/studioCommand/interfaces/reducers/UpdatePosterInfoState'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import type { PromiseVoid } from '../useCommands'
import { usePosterInfoValidation } from '../validations/usePosterInfoValidation'

export type UsePosterInfoUpdateCommand = [UpdatePosterInfoState, PromiseVoid]

export const usePosterInfoUpdateCommand = (): UsePosterInfoUpdateCommand => {
	const { updatePosterInfoCommandState } = useAppSelector(({ commands: { updatePosterInfo: updatePosterInfoCommandState } }: StateTree) => ({
		updatePosterInfoCommandState,
	}))
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const sendUpdatePosterInfoCommand = useCallback(() => updatePosterInfo()(dispatch, getState), [dispatch, getState])
	const validate = usePosterInfoValidation()

	const sendUpdateWithValidation = async (): Promise<void> => {
		dispatch(updatePosterInfoStart())

		const isValid = await validate()

		if (isValid) {
			sendUpdatePosterInfoCommand()
		} else {
			console.error('invalid posterInfo values')
			dispatch(updatePosterInfoFailure())
		}
	}

	return [updatePosterInfoCommandState, sendUpdateWithValidation]
}
