import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import startPartBGame from '../../../redux/studioCommand/actions/startPartBGame'
import toggleStartPartBGame from '../../../redux/studioCommand/actions/toggleStartPartBGame'
import { useAppDispatch, useAppSelector } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'

type UseStartPartBGame = [PromiseVoid, ToggleCommand]

export default function useStartPartBGame(): UseStartPartBGame {
	const dispatch = useAppDispatch()
	const isAvailable = useAppSelector(
		({
			wsMessage: {
				startPartBGame: { isAvailable },
			},
		}: StateTree) => isAvailable,
	)
	const toggleStartPartBGameButton = useCallback((isAvailable: boolean) => toggleStartPartBGame(isAvailable)(dispatch), [dispatch])
	const sendStartPartBGame = useCallback(() => startPartBGame()(dispatch), [dispatch])

	useEffect(() => {
		toggleStartPartBGameButton(isAvailable)
	}, [isAvailable, toggleStartPartBGameButton])

	return [sendStartPartBGame, toggleStartPartBGameButton]
}
