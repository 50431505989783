import type { Dispatch } from 'redux'
import type { NextPosterInfoStateValidAction } from '../interfaces/NextPosterInfoStateValidAction'
import type { PosterInfoStateValidProps } from '../interfaces/PosterInfoStateValidProps'
import type { UpdateNextPosterInfoStateAction } from '../interfaces/UpdateNextPosterInfoStateAction'
import type { UpdatePosterInfoStateProps } from '../interfaces/UpdatePosterInfoStateProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

export const updateNextPosterInfoState = (props: UpdatePosterInfoStateProps): UpdateNextPosterInfoStateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_POSTER_INFO_STATE_UPDATE,
		props,
	}
}

export const isValidNextPosterInfoState = (props: PosterInfoStateValidProps): NextPosterInfoStateValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_POSTER_INFO_STATE_VALID,
		props,
	}
}

export default function nextPosterInfoState(props: UpdatePosterInfoStateProps) {
	return (dispatch: Dispatch): void => {
		dispatch(updateNextPosterInfoState(props))
	}
}
