export enum REDUX_STUDIO_COMMAND_TYPES {
	LEAGUES_RESET_TOGGLE = 'LEAGUES_RESET_TOGGLE',
	LEAGUES_RESET_DISABLED_TOGGLE = 'LEAGUES_RESET_DISABLED_TOGGLE',

	SEASON_TRANSITION_TOGGLE = 'SEASON_TRANSITION_TOGGLE',
	SEASON_TRANSITION_DISABLED_TOGGLE = 'SEASON_TRANSITION_DISABLED_TOGGLE',

	EXECUTE_SHOW_START = 'EXECUTE_SHOW_START',
	EXECUTE_SHOW_FAILURE = 'EXECUTE_SHOW_FAILURE',
	EXECUTE_SHOW_SUCCESS = 'EXECUTE_SHOW_SUCCESS',

	WARMUP_COMMAND_START = 'WARMUP_COMMAND_START',
	WARMUP_COMMAND_FAILURE = 'WARMUP_COMMAND_FAILURE',
	WARMUP_COMMAND_SUCCESS = 'WARMUP_COMMAND_SUCCESS',

	START_COUNTDOWN_AND_SHOW_START = 'START_COUNTDOWN_AND_SHOW_START',
	START_COUNTDOWN_AND_SHOW_FAILURE = 'START_COUNTDOWN_AND_SHOW_FAILURE',
	START_COUNTDOWN_AND_SHOW_SUCCESS = 'START_COUNTDOWN_AND_SHOW_SUCCESS',
	START_COUNTDOWN_AND_SHOW_TOGGLE = 'START_COUNTDOWN_AND_SHOW_TOGGLE',

	GET_WINNERS_START = 'GET_WINNERS_START',
	GET_WINNERS_FAILURE = 'GET_WINNERS_FAILURE',
	GET_WINNERS_SUCCESS = 'GET_WINNERS_SUCCESS',

	START_GAME_START = 'START_GAME_START',
	START_GAME_FAILURE = 'START_GAME_FAILURE',
	START_GAME_SUCCESS = 'START_GAME_SUCCESS',
	START_GAME_TOGGLE = 'START_GAME_TOGGLE',

	PREPARE_CELEBRATION_START = 'PREPARE_CELEBRATION_START',
	PREPARE_CELEBRATION_FAILURE = 'PREPARE_CELEBRATION_FAILURE',
	PREPARE_CELEBRATION_SUCCESS = 'PREPARE_CELEBRATION_SUCCESS',
	PREPARE_CELEBRATION_TOGGLE = 'PREPARE_CELEBRATION_TOGGLE',

	DISPLAY_BEAT_GUEST_START = 'DISPLAY_BEAT_GUEST_START',
	DISPLAY_BEAT_GUEST_FAILURE = 'DISPLAY_BEAT_GUEST_FAILURE',
	DISPLAY_BEAT_GUEST_SUCCESS = 'DISPLAY_BEAT_GUEST_SUCCESS',
	DISPLAY_BEAT_GUEST_TOGGLE = 'DISPLAY_BEAT_GUEST_TOGGLE',

	PICK_WINNERS_START = 'PICK_WINNERS_START',
	PICK_WINNERS_FAILURE = 'PICK_WINNERS_FAILURE',
	PICK_WINNERS_SUCCESS = 'PICK_WINNERS_SUCCESS',
	PICK_WINNERS_TOGGLE = 'PICK_WINNERS_TOGGLE',

	DISPLAY_WINNERS_START = 'DISPLAY_WINNERS_START',
	DISPLAY_WINNERS_FAILURE = 'DISPLAY_WINNERS_FAILURE',
	DISPLAY_WINNERS_SUCCESS = 'DISPLAY_WINNERS_SUCCESS',
	DISPLAY_WINNERS_TOGGLE = 'DISPLAY_WINNERS_TOGGLE',

	SHOW_LUCKY_WINNERS_START = 'SHOW_LUCKY_WINNERS_START',
	SHOW_LUCKY_WINNERS_FAILURE = 'SHOW_LUCKY_WINNERS_FAILURE',
	SHOW_LUCKY_WINNERS_SUCCESS = 'SHOW_LUCKY_WINNERS_SUCCESS',
	SHOW_LUCKY_WINNERS_TOGGLE = 'SHOW_LUCKY_WINNERS_TOGGLE',

	START_PART_B_GAME_START = 'START_PART_B_GAME_START',
	START_PART_B_GAME_FAILURE = 'START_PART_B_GAME_FAILURE',
	START_PART_B_GAME_SUCCESS = 'START_PART_B_GAME_SUCCESS',
	START_PART_B_GAME_TOGGLE = 'START_PART_B_GAME_TOGGLE',

	STOP_SHOW_START = 'STOP_SHOW_START',
	STOP_SHOW_FAILURE = 'STOP_SHOW_FAILURE',
	STOP_SHOW_SUCCESS = 'STOP_SHOW_SUCCESS',
	STOP_SHOW_TOGGLE = 'STOP_SHOW_TOGGLE',

	RESET_CHALLENGES_START = 'RESET_CHALLENGES_START',
	RESET_CHALLENGES_FAILURE = 'RESET_CHALLENGES_FAILURE',
	RESET_CHALLENGES_SUCCESS = 'RESET_CHALLENGES_SUCCESS',
	RESET_CHALLENGES_TOGGLE = 'RESET_CHALLENGES_TOGGLE',

	UPDATE_POSTER_INFO_COMMAND_START = 'UPDATE_POSTER_INFO_COMMAND_START',
	UPDATE_POSTER_INFO_COMMAND_FAILURE = 'UPDATE_POSTER_INFO_COMMAND_FAILURE',
	UPDATE_POSTER_INFO_COMMAND_SUCCESS = 'UPDATE_POSTER_INFO_COMMAND_SUCCESS',

	UPDATE_NEXT_POSTER_INFO_COMMAND_START = 'UPDATE_NEXT_POSTER_INFO_COMMAND_START',
	UPDATE_NEXT_POSTER_INFO_COMMAND_FAILURE = 'UPDATE_NEXT_POSTER_INFO_COMMAND_FAILURE',
	UPDATE_NEXT_POSTER_INFO_COMMAND_SUCCESS = 'UPDATE_NEXT_POSTER_INFO_COMMAND_SUCCESS',

	OVERWRITE_GOALS_COMMAND_START = 'OVERWRITE_GOALS_COMMAND_START',
	OVERWRITE_GOALS_COMMAND_FAILURE = 'OVERWRITE_GOALS_COMMAND_FAILURE',
	OVERWRITE_GOALS_COMMAND_SUCCESS = 'OVERWRITE_GOALS_COMMAND_SUCCESS',

	FORCE_UPDATE_VERSION_COMMAND_START = 'FORCE_UPDATE_VERSION_COMMAND_START',
	FORCE_UPDATE_VERSION_COMMAND_FAILURE = 'FORCE_UPDATE_VERSION_COMMAND_FAILURE',
	FORCE_UPDATE_VERSION_COMMAND_SUCCESS = 'FORCE_UPDATE_VERSION_COMMAND_SUCCESS',

	SAVE_SHOW_INFORMATION_COMMAND_START = 'SAVE_SHOW_INFORMATION_COMMAND_START',
	SAVE_SHOW_INFORMATION_COMMAND_FAILURE = 'SAVE_SHOW_INFORMATION_COMMAND_FAILURE',
	SAVE_SHOW_INFORMATION_COMMAND_SUCCESS = 'SAVE_SHOW_INFORMATION_COMMAND_SUCCESS',

	SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_START = 'SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_START',
	SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_FAILURE = 'SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_FAILURE',
	SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_SUCCESS = 'SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_SUCCESS',

	GET_SCHEDULED_SHOW_INFORMATION_COMMAND_START = 'GET_SCHEDULED_SHOW_INFORMATION_COMMAND_START',
	GET_SCHEDULED_SHOW_INFORMATION_COMMAND_FAILURE = 'GET_SCHEDULED_SHOW_INFORMATION_COMMAND_FAILURE',
	GET_SCHEDULED_SHOW_INFORMATION_COMMAND_SUCCESS = 'GET_SCHEDULED_SHOW_INFORMATION_COMMAND_SUCCESS',

	OVERWRITE_DEFAULT_VALUES_COMMAND_START = 'OVERWRITE_DEFAULT_VALUES_COMMAND_START',
	OVERWRITE_DEFAULT_VALUES_COMMAND_FAILURE = 'OVERWRITE_DEFAULT_VALUES_COMMAND_FAILURE',
	OVERWRITE_DEFAULT_VALUES_COMMAND_SUCCESS = 'OVERWRITE_DEFAULT_VALUES_COMMAND_SUCCESS',

	UPDATE_NEXT_SHOW_TIME_COMMAND_START = 'UPDATE_NEXT_SHOW_TIME_COMMAND_START',
	UPDATE_NEXT_SHOW_TIME_COMMAND_FAILURE = 'UPDATE_NEXT_SHOW_TIME_COMMAND_FAILURE',
	UPDATE_NEXT_SHOW_TIME_COMMAND_SUCCESS = 'UPDATE_NEXT_SHOW_TIME_COMMAND_SUCCESS',

	UPDATE_NEWS_COMMAND_START = 'UPDATE_NEWS_COMMAND_START',
	UPDATE_NEWS_COMMAND_FAILURE = 'UPDATE_NEWS_COMMAND_FAILURE',
	UPDATE_NEWS_COMMAND_SUCCESS = 'UPDATE_NEWS_COMMAND_SUCCESS',

	PICK_SEASON_WINNER_START = 'PICK_SEASON_WINNER_START',
	PICK_SEASON_WINNER_FAILURE = 'PICK_SEASON_WINNER_FAILURE',
	PICK_SEASON_WINNER_SUCCESS = 'PICK_SEASON_WINNER_SUCCESS',

	SHOW_TYPE_SELECT = 'SHOW_TYPE_SELECT',
}
