import { useCallback } from 'react'
import { videoStateValid } from '../../../redux/dashboardInputs/actions/videoState'
import type { VideoStateValidActionProps } from '../../../redux/dashboardInputs/interfaces/VideoStateValidActionProps'
import type { StateTree } from '../../../redux/interfaces'
import { isURLFileValid } from '../../validation/isURLFileValid'
import { useAppDispatch, useAppSelector } from '..'
import type { VideoUrlState } from '../../../redux/dashboardInputs/interfaces/VideoUrlState'
import type { GuestScoreState } from '../../../redux/dashboardInputs/interfaces/GuestScoreState'

type Fields = {
	highQualityVideoUrl: VideoUrlState
	videoUrl: VideoUrlState
	lowQualityVideoUrl: VideoUrlState
	guestScore: GuestScoreState
}

export const validateFields =
	(
		{ highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore }: Fields,
		triggerDispatchValidation: (props: VideoStateValidActionProps) => void,
	) =>
	async () => {
		const areVideosUrlValid = await Promise.all([
			isURLFileValid(highQualityVideoUrl.value),
			isURLFileValid(videoUrl.value),
			isURLFileValid(lowQualityVideoUrl.value),
		])
		const [isHighQualityVideoUrlValid, isVideoUrlValid, isLowQualityVideoUrlValid] = areVideosUrlValid
		const areVideosValid = areVideosUrlValid.every((element: boolean) => element === true)
		const isGuestScoreValid = !Number.isNaN(guestScore.value)

		if (areVideosValid && isGuestScoreValid) {
			return true
		}

		triggerDispatchValidation({ isHighQualityVideoUrlValid, isVideoUrlValid, isLowQualityVideoUrlValid, isGuestScoreValid })

		return false
	}

export const useCountdownShowValidation = (): (() => Promise<boolean>) => {
	const { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore } = useAppSelector(
		({
			dashboardInputs: {
				video: { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore },
			},
		}: StateTree) => ({ highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore }),
	)
	const dispatch = useAppDispatch()
	const triggerDispatchValidation = useCallback(
		(props: VideoStateValidActionProps) => {
			dispatch(videoStateValid(props))
		},
		[dispatch],
	)

	return validateFields({ highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore }, triggerDispatchValidation)
}
