import { maxMessages } from '../../../config/const'
import { getTimeNow } from '../../../utils/getTimeNow/getTimeNow'
import { onMessageEvents } from '../../../utils/webSocket/onMessageEvents'
import type { ChatMessage } from '../interfaces/ChatMessage'
import type { OnMessageActions } from '../interfaces/onWSActions'
import type { WebSocketMessageState } from '../interfaces/WebSocketMessageState'
import { REDUX_WEBSOCKET_TYPES } from '../types'

const initialState: WebSocketMessageState = {
	chatMessages: [],
	startCountdownShowAvailable: {
		startLiveShow: false,
		gameCountdown: false,
	},
	startGame: {
		isAvailable: true,
		values: null,
	},
	displayBeatGuest: {
		isAvailable: true,
		result: null,
	},
	prepareCelebration: { isAvailable: true, data: null },
	pickWinners: {
		isAvailable: true,
		data: null,
	},
	resetChallenges: {
		isAvailable: true,
		data: null,
	},
	showLuckyWinners: {
		isAvailable: true,
	},
	startPartBGame: {
		isAvailable: true,
	},
	stopShow: {
		isAvailable: true,
		data: null,
	},
	executeShowStatus: false,
	connected: false,
}

const addNewMessage = (state: WebSocketMessageState, message: ChatMessage): ChatMessage[] => {
	const { chatMessages: chatHistory } = state
	let newHistory = []

	if (Object.keys(message).length === 0) {
		return chatHistory
	}

	if (chatHistory.length === maxMessages) {
		const clonedMessages = [...chatHistory]
		clonedMessages.shift()
		newHistory = [...clonedMessages, message]
	} else {
		newHistory = [...chatHistory, message]
	}

	return newHistory
}

const markMessagesRemoved = (state: WebSocketMessageState, playFabId: string): ChatMessage[] => {
	const { chatMessages: chatHistory } = state
	return chatHistory.map((chatMessage) => {
		if (chatMessage.playFabId !== playFabId) return chatMessage
		return {
			...chatMessage,
			isRemoved: true,
		}
	})
}

export default function onWSAction(state = initialState, action: OnMessageActions): WebSocketMessageState {
	switch (action.type) {
		case REDUX_WEBSOCKET_TYPES.WS_OPEN:
			console.info('WS:CONNECTED ', getTimeNow())
			return { ...state, connected: true }
		case REDUX_WEBSOCKET_TYPES.WS_CLOSE:
			console.info('WS:DISCONNECTED ', getTimeNow())
			return { ...state, connected: false }
		case REDUX_WEBSOCKET_TYPES.WS_ERROR:
			console.error('WS:ERROR', action.payload.message)
			return state
		case REDUX_WEBSOCKET_TYPES.WS_MESSAGE: {
			const {
				payload: { message },
			} = action

			const parsedMessage = JSON.parse(message)

			if (Object.keys(parsedMessage).length === 0) {
				return state
			}

			const {
				chatMessage,
				removeMessagesFromPlayerId,
				startCountdownShowAvailable,
				startGame,
				prepareCelebration,
				displayBeatGuest,
				pickWinners,
				resetChallenges,
				showLuckyWinners,
				startPartBGame,
				stopShow,
				executeShowStatus,
			} = onMessageEvents(parsedMessage, state)

			const chatHistory = removeMessagesFromPlayerId
				? markMessagesRemoved(state, removeMessagesFromPlayerId)
				: addNewMessage(state, chatMessage)

			return {
				...state,
				chatMessages: chatHistory,
				startCountdownShowAvailable,
				startGame,
				prepareCelebration,
				displayBeatGuest,
				pickWinners,
				resetChallenges,
				showLuckyWinners,
				startPartBGame,
				stopShow,
				executeShowStatus,
			}
		}
		case REDUX_WEBSOCKET_TYPES.WS_RESET_STATE:
			return { ...initialState, chatMessages: state.chatMessages }

		default:
			return state
	}
}
