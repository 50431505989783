import type { Dispatch } from 'redux'
import type { OnDemandVideoStateUpdateAction } from '../interfaces/OnDemandVideoStateUpdateAction'
import type { VideoStateUpdateActionProps } from '../interfaces/VideoStateUpdateActionProps'
import type { OnDemandVideoStateValidAction } from '../interfaces/OnDemandVideoStateValidAction'
import type { VideoStateValidActionProps } from '../interfaces/VideoStateValidActionProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const onDemandVideoStateUpdate = (props: VideoStateUpdateActionProps): OnDemandVideoStateUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.ON_DEMAND_VIDEO_STATE_UPDATE,
		props,
	}
}

export const onDemandVideoStateValid = (props: VideoStateValidActionProps): OnDemandVideoStateValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.ON_DEMAND_VIDEO_STATE_VALID,
		props,
	}
}

export default function onDemandVideoState(props: VideoStateUpdateActionProps) {
	return (dispatch: Dispatch): void => {
		const { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore } = props

		dispatch(onDemandVideoStateUpdate({ highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore }))
	}
}
