import { useCallback } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import updateNextPosterInfo, {
	updateNextPosterInfoFailure,
	updateNextPosterInfoStart,
} from '../../../redux/studioCommand/actions/updateNextPosterInfo'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import { useNextPosterInfoValidation } from '../validations/useNextPosterInfoValidation'
import type { UsePosterInfoUpdateCommand } from './usePosterInfoUpdateCommand'

export const useNextPosterInfoUpdateCommand = (): UsePosterInfoUpdateCommand => {
	const { updateNextPosterInfoCommandState } = useAppSelector(
		({ commands: { updateNextPosterInfo: updateNextPosterInfoCommandState } }: StateTree) => ({
			updateNextPosterInfoCommandState,
		}),
	)
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const sendUpdateNextPosterInfoCommand = useCallback(() => updateNextPosterInfo()(dispatch, getState), [dispatch, getState])
	const validate = useNextPosterInfoValidation()

	const sendUpdateWithValidation = async (): Promise<void> => {
		dispatch(updateNextPosterInfoStart())

		const isValid = await validate()

		if (isValid) {
			sendUpdateNextPosterInfoCommand()
		} else {
			console.error('invalid nextPosterInfo values')
			dispatch(updateNextPosterInfoFailure())
		}
	}

	return [updateNextPosterInfoCommandState, sendUpdateWithValidation]
}
