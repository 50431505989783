import { useBeatGuestValidation } from './useBeatGuestValidation'
import { useCountdownShowValidation } from './useCountdownShowValidation'
import { usePosterInfoValidation } from './usePosterInfoValidation'
import { useNextPosterInfoValidation } from './useNextPosterInfoValidation'
import { usePickWinnersValidation } from './usePickWinnersValidation'
import { useTimestampsValidation } from './useTimestampsValidation'
import { useOnDemandVideoGuestScoreValidation } from './useOnDemandVideoGuestScoreValidation'

export const useAllFieldsValidation = (): (() => Promise<boolean>) => {
	const validateBeatGuest = useBeatGuestValidation()
	const validateVideoGuestScore = useCountdownShowValidation()
	const validateOnDemandVideoGuestScore = useOnDemandVideoGuestScoreValidation()
	const validatePosterInfo = usePosterInfoValidation()
	const validateNextPosterInfo = useNextPosterInfoValidation()
	const validatePickWinnersData = usePickWinnersValidation()
	const validateShowTimestamps = useTimestampsValidation()

	const validate = async (): Promise<boolean> => {
		const isBeatGuestValid = validateBeatGuest()
		const isPickWinnersDataValid = validatePickWinnersData()
		const showTimestampsValid = validateShowTimestamps()

		const promiseValidations = [validateVideoGuestScore(), validateOnDemandVideoGuestScore(), validatePosterInfo(), validateNextPosterInfo()]

		const [isVideoGuestScoreValid, isOnDemandVideoGuestScoreValid, isPosterInfoValid, isNextPosterInfoValid] =
			await Promise.all(promiseValidations)

		return (
			isBeatGuestValid &&
			isPickWinnersDataValid &&
			isVideoGuestScoreValid &&
			isOnDemandVideoGuestScoreValid &&
			isPosterInfoValid &&
			isNextPosterInfoValid &&
			showTimestampsValid
		)
	}

	return validate
}
