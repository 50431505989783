import { useCallback } from 'react'
import videoStateUpdate from '../../../redux/dashboardInputs/actions/videoState'
import type { GuestScoreState } from '../../../redux/dashboardInputs/interfaces/GuestScoreState'
import type { VideoStateUpdateActionProps } from '../../../redux/dashboardInputs/interfaces/VideoStateUpdateActionProps'
import type { VideoUrlState } from '../../../redux/dashboardInputs/interfaces/VideoUrlState'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

export type UseVideoState = [VideoUrlState, VideoUrlState, VideoUrlState, GuestScoreState, (props: VideoStateUpdateActionProps) => void]

export const useVideoState = (): UseVideoState => {
	const { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore } = useAppSelector(
		({
			dashboardInputs: {
				video: { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore },
			},
		}: StateTree) => ({ highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore }),
	)
	const dispatch = useAppDispatch()
	const updateVideoState = useCallback((props: VideoStateUpdateActionProps) => videoStateUpdate(props)(dispatch), [dispatch])

	return [highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore, updateVideoState]
}
