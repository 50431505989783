import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { StartPartBGameFailureAction } from '../interfaces/actions/StartPartBGameFailureAction'
import type { StartPartBGameStartAction } from '../interfaces/actions/StartPartBGameStartAction'
import type { StartPartBGameSuccessAction } from '../interfaces/actions/StartPartBGameSuccessAction'
import type { StartPartBGame } from '../interfaces/StartPartBGame'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const startPartBGameStart = (): StartPartBGameStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_PART_B_GAME_START }
}

const startPartBGameFailure = (): StartPartBGameFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_PART_B_GAME_FAILURE }
}

const startPartBGameSuccess = (): StartPartBGameSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_PART_B_GAME_SUCCESS }
}

export default function startPartBGame() {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(startPartBGameStart())

		try {
			const startPartBGameCommand: StartPartBGame = {
				command: 'startPartBGame',
			}
			await sendCommandToStudio(startPartBGameCommand)
			dispatch(startPartBGameSuccess())
		} catch {
			dispatch(startPartBGameFailure())
		}
	}
}
