import { useCallback } from 'react'
import onDemandVideoStateUpdate from '../../../redux/dashboardInputs/actions/onDemandVideoState'
import type { VideoStateUpdateActionProps } from '../../../redux/dashboardInputs/interfaces/VideoStateUpdateActionProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'
import type { UseVideoState } from './useVideoState'

export const useOnDemandVideoState = (): UseVideoState => {
	const { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore } = useAppSelector(
		({
			dashboardInputs: {
				onDemandVideo: { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore },
			},
		}: StateTree) => ({ highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore }),
	)
	const dispatch = useAppDispatch()
	const updateOnDemandVideoState = useCallback((props: VideoStateUpdateActionProps) => onDemandVideoStateUpdate(props)(dispatch), [dispatch])

	return [highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore, updateOnDemandVideoState]
}
