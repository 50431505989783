import type { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { CardBody, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import { useBeatGuestMessagesState } from '../../utils/hooks/dashboardInputs/useBeatGuestMessages'

function BeatGuestMessages(): ReactElement<typeof CardWrapper, FunctionComponent<typeof CardWrapper>> {
	const [yesFirst, yesSecond, yesOnDemand, noFirst, noSecond, updateGuestMessages] = useBeatGuestMessagesState()

	const onChangeBeatTheGuestYesFirst = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event

		updateGuestMessages({
			yesSecond: yesSecond.value,
			yesOnDemand: yesOnDemand.value,
			noFirst: noFirst.value,
			noSecond: noSecond.value,
			yesFirst: value,
		})
	}

	const onChangeBeatTheGuestYesSecond = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateGuestMessages({
			yesSecond: value,
			yesOnDemand: yesOnDemand.value,
			noFirst: noFirst.value,
			noSecond: noSecond.value,
			yesFirst: yesFirst.value,
		})
	}

	const onChangeBeatTheGuestYesOnDemand = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateGuestMessages({
			yesSecond: yesSecond.value,
			yesOnDemand: value,
			noFirst: noFirst.value,
			noSecond: noSecond.value,
			yesFirst: yesFirst.value,
		})
	}

	const onChangeBeatTheGuestNoFirst = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateGuestMessages({
			yesSecond: yesSecond.value,
			yesOnDemand: yesOnDemand.value,
			noFirst: value,
			noSecond: noSecond.value,
			yesFirst: yesFirst.value,
		})
	}

	const onChangeBeatTheGuestNoSecond = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateGuestMessages({
			yesSecond: yesSecond.value,
			yesOnDemand: yesOnDemand.value,
			noFirst: noFirst.value,
			noSecond: value,
			yesFirst: yesFirst.value,
		})
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Beat Guest Messages</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>Yes - First line</label>
					<CardInput
						value={yesFirst.value}
						isValid={yesFirst.isValid}
						type="text"
						onChange={onChangeBeatTheGuestYesFirst}
						placeholder="Yes - First line"
					/>
				</CardFieldSet>
				<CardFieldSet>
					<label>Yes - Second line</label>
					<CardInput
						value={yesSecond.value}
						isValid={yesSecond.isValid}
						type="text"
						onChange={onChangeBeatTheGuestYesSecond}
						placeholder="Yes - Second line"
					/>
				</CardFieldSet>
				<CardFieldSet>
					<label>Yes - Second line (On Demand)</label>
					<CardInput
						value={yesOnDemand.value}
						isValid={yesOnDemand.isValid}
						type="text"
						onChange={onChangeBeatTheGuestYesOnDemand}
						placeholder="Yes - Second line (On Demand)"
					/>
				</CardFieldSet>
				<CardFieldSet>
					<label>No - First line</label>
					<CardInput
						value={noFirst.value}
						isValid={noFirst.isValid}
						type="text"
						onChange={onChangeBeatTheGuestNoFirst}
						placeholder="No - First line"
					/>
				</CardFieldSet>
				<CardFieldSet>
					<label>No - Second line</label>
					<CardInput
						value={noSecond.value}
						isValid={noSecond.isValid}
						type="text"
						onChange={onChangeBeatTheGuestNoSecond}
						placeholder="No - Second line"
					/>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default BeatGuestMessages
