import type { Dispatch } from 'redux'
import type { StartPartBGameToggleAction } from '../interfaces/actions/StartPartBGameToggleAction'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const startPartBGameToggle = (isAvailable: boolean): StartPartBGameToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_PART_B_GAME_TOGGLE, isAvailable }
}

export default function toggleStartPartBGame(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(startPartBGameToggle(isAvailable))
	}
}
