import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { ShowLuckyWinnersFailureAction } from '../interfaces/actions/ShowLuckyWinnersFailureAction'
import type { ShowLuckyWinnersStartAction } from '../interfaces/actions/ShowLuckyWinnersStartAction'
import type { ShowLuckyWinnersSuccessAction } from '../interfaces/actions/ShowLuckyWinnersSuccessAction'
import type { ShowLuckyWinners } from '../interfaces/ShowLuckyWinners'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const showLuckyWinnersStart = (): ShowLuckyWinnersStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SHOW_LUCKY_WINNERS_START }
}

const showLuckyWinnersFailure = (): ShowLuckyWinnersFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SHOW_LUCKY_WINNERS_FAILURE }
}

const showLuckyWinnersSuccess = (): ShowLuckyWinnersSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SHOW_LUCKY_WINNERS_SUCCESS }
}

export default function showLuckyWinners() {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(showLuckyWinnersStart())

		try {
			const showLuckyWinnersCommand: ShowLuckyWinners = {
				command: 'showLuckyWinners',
			}
			await sendCommandToStudio(showLuckyWinnersCommand)
			dispatch(showLuckyWinnersSuccess())
		} catch {
			dispatch(showLuckyWinnersFailure())
		}
	}
}
